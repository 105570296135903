<template>
  <el-progress
    :stroke-width="width"
    :percentage="percentage"
    :text-inside="showProgress"
    :color="percentageColor"
  />
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: false,
      default: 0,
    },
    width: {
      type: Number,
      required: true,
    },
    showProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    percentageColor() {
      if (this.percentage >= 0 && this.percentage < 30) {
        return 'rgb(254,57,57)';
      } if (this.percentage >= 30 && this.percentage <= 80) {
        return 'rgb(241,177,67)';
      }

      return 'rgb(43,194,83)';
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
::v-deep {
  .el-progress-bar__innerText {
    margin-bottom: 4px;
    font-size 11px
  }
}
</style>
